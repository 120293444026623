<template>
  <div  :class="headerBg == 2 ? 'header-box' : 'header-box-active'">
    <div class="headerwrap">
      <div class="logo">
        <router-link :to="{ name: 'home' }"
          ><img src="../assets/img/logo.png"
        /></router-link>
      </div>
      <div class="navigation" @click="tab">
        <p :class="showActive == '/' ? 'navigation_active' : ''">
          <router-link :to="{ name: 'home' }">首页</router-link>
        </p>
        <p :class="showActive == '/carList' ? 'navigation_active' : ''">
          <router-link :to="{ name: 'carList' }">自驾租车</router-link>
        </p>
        <!-- <p><a :href="FunPublic.url + 'longRent.html'">长租</a></p> -->
        <p
          :class="
            showActive == '/newList' || showActive == '/newListChildren'
              ? 'navigation_active'
              : ''
          "
        >
          <router-link :to="{ name: 'newList' }">枫行世界</router-link>
        </p>
        <p :class="showActive == '/companyProfile' ? 'navigation_active' : ''">
          <router-link :to="{ name: 'companyProfile' }">走进枫叶</router-link>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {}, //添加注册组件事件
  data() {
    return {
      showActive: "/",
      headerBg: 2,
    };
  },
  created() {
    this.showActive = this.$route.path;
    if (this.showActive == "/") {
      this.headerBg = 2;
    } else {
      this.headerBg = 1;
    }
  },
  methods: {
    tab() {},
    // 改变顶栏背景色
    parentHandleclick(e) {
      if (e == 0) {
        this.headerBg = 2;
      } else {
        this.headerBg = 1;
      }
    },
  },
};
</script>
<style scoped>
.header-box-active {
  min-width: 100%;
  height: 100px;
  line-height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #020202;
  transition: background-color 0.5s linear 0s;
  z-index: 100;
}

.header-box {
  min-width: 100%;
  height: 100px;
  line-height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s linear 0s;
  z-index: 100;
}

.logo {
  width: 186px;
  height: 37px;
}

.logo img {
  width: 100%;
  height: 100%;
  display: block;
}

.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  height: 100%;
  font-size: 16px;
}
.navigation p {
  margin-right: 116px;
}
.navigation p:last-child {
  margin-right: 0;
}
.navigation p a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

.navigation .navigation_active a {
  color: rgba(232, 200, 161, 1);
}
.headerwrap{
     display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 1360px;
    margin: 0 auto;
}
@media screen and (max-width:1680px)and (max-height:1050px){
   .header-box {
    padding: 0 208px;
  }
  .header-box-active {
    padding: 0 208px;
  }
   .headerwrap{
    width: 100%;
  }
}
@media screen and (max-width: 1600px){
   .header-box {
    padding: 0 200px;
  }
  .header-box-active {
    padding: 0 200px;
  }
   .headerwrap{
    width: 100%;
  }
}
@media screen and (max-width: 1440px) {
  .headerwrap{
    width: 100%;
  }
  .navigation p a {
    font-size: 14px;
  }
  .logo {
    width: 160px;
    height: 30px;
  }
  .header-box {
    padding: 0 180px;
  }
  .header-box-active {
    padding: 0 180px;
  }
  .navigation p {
    margin-right: 70px;
  }
}
@media screen and (max-width: 1366px) {
   .headerwrap{
    width: 100%;
  }
  .navigation p a {
    font-size: 14px;
  }
  .logo {
    width: 160px;
    height: 30px;
  }
  .header-box {
    padding: 0 176px;
  }
  .header-box-active {
    padding: 0 176px;
  }
  .navigation p {
    margin-right: 70px;
  }
}
@media screen and (max-width: 1280px) {
   .headerwrap{
    width: 100%;
  }
  .navigation p a {
    font-size: 14px;
  }
  .logo {
    width: 160px;
    height: 30px;
  }
  .header-box {
    padding: 0 146px;
  }
  .header-box-active {
    padding: 0 146px;
  }
  .navigation p {
    margin-right: 70px;
  }
}
</style>
