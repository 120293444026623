<template>
  <div class="foot">
    <div class="foot_main">
      <div class="flex-col foot-main-item">
        <img src="../assets/img/logo.png" />
        <span class="marginbom">
          <a  target="_blank" style="cursor:pointer" href="https://beian.miit.gov.cn">
            沪ICP备2024052612号
          </a>
          <br />
          <!-- &copy;{{ copyright_code }} -->
        </span>
        <!-- <div class="gonganContent">
          <img src="../assets/img/icon_sm.png" class="gonganimg" />
          <span>辽公网安备 21010502000599号</span>
        </div> -->
      </div>
      <div class="flex-col foot-main-item footeritems">
        <h4>关于枫叶出行</h4>
        <span>
          <router-link :to="{ name: 'companyProfile' }">关于我们</router-link>
        </span>
        <!-- <span class="hand">
          <router-link :to="{ name: 'agreement' }"
            >枫叶出行服务协议</router-link
          >
        </span> -->
        <span class="hand">
          <router-link :to="{ name: 'problem' }">需要帮助</router-link>
        </span>
      </div>
      <div class="flex-col foot-main-item footeritems">
        <h4>联系我们</h4>
        <span class="email">{{contact_email}}</span>
        <span class="tel">{{ contact_tel }}</span>
        <span class="add">上海市奉贤区莘奉公路4869号1层</span>
      </div>
      <div class="flex-col foot-main-item footeritems">
        <h4>商务合作</h4>
        <span class="email">{{ business_email }}</span>
        <span class="tel">{{ business_tel }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { apiFooterMsg } from "../request/api.js";
export default {
  data() {
    return {
      contact_tel: "",
      contact_email: "",
      contact_addr: "",
      business_email: "",
      business_tel: "",
      copyright_code: "",
      copyright_name: "",
    };
  },
  methods: {
    getFooterMsg() {
      apiFooterMsg({}).then((res) => {
        if (res.data.code == 200) {
          this.contact_tel = res.data.data.contact_tel;
          this.contact_email = res.data.data.contact_email;
          this.contact_addr = res.data.data.contact_addr;
          this.business_email = res.data.data.business_email;
          this.business_tel = res.data.data.business_tel;
          this.copyright_code = res.data.data.copyright_code;
          this.copyright_name = res.data.data.copyright_name;
        }
      });
    },
  },
  mounted() {},
  created() {
    this.getFooterMsg();
  },
};
</script>
<style scoped>
.marginbom{
  margin-bottom: 12px !important;
}
.footeritems span{
  margin-bottom: 12px !important;
}
.footeritems span:last-child{
  margin-bottom: 0px !important;
}
.foot a {
  color: #ffffff;
    color: rgba(255, 255, 255, .8);
}
.foot {
  position: relative;
    bottom: 0;
    width: 100%;
    height: auto !important;
    background: #000000;
    color: #ffffff;
    padding: 25px 280px 25px 280px;
}
.foot_main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.foot-main-item img {
  width: 186px;
  height: 37px;
  margin-bottom: 29px;
}
.foot-main-item span {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 3px;
  color: rgba(255, 255, 255, .8);
}
.foot-main-item h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 30px;
}
.email,
.tel,
.add {
  padding-left: 24px;
  position: relative;
}
.email::after {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 14px;
  height: 10px;
  background: url(../assets/img/icon_email.png);
  background-size: 100%;
}
.tel::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 14px;
  height: 14px;
  background: url(../assets/img/icon_tel.png);
  background-size: 100%;
}
.add::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 2px;
  width: 10px;
  height: 13px;
  background: url(../assets/img/icon_add.png);
  background-size: 100%;
}

.gonganContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.gonganContent .gonganimg {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.gonganContent span {
  color: rgba(255, 255, 255, .8);
  font-size: 14px;
}
@media screen and (max-width:1600px) and (max-height:900px) {
   .foot-main-item img {
        width: 101px;
        height: 20px;
    }
    .foot-main-item span {
        font-size: 12px;
    }
    .gonganContent span {
        font-size: 12px;
    }
    .foot-main-item h4{
      margin-bottom: 14px;
      font-size: 15px;
    }
    
}
@media screen and (max-width:1400px) and (max-height:1050px) {
   .foot-main-item img {
        width: 101px;
        height: 20px;
    }
    .foot-main-item span {
        font-size: 12px;
    }
    .gonganContent span {
        font-size: 12px;
    }
    .foot-main-item h4{
      margin-bottom: 14px;
      font-size: 15px;
    }
    
}
@media screen and (max-width:1280px) and (max-height:1024px) {
  .foot-main-item img {
        width: 101px;
        height: 20px;
    }
    .foot-main-item span {
        font-size: 12px;
    }
    .gonganContent span {
        font-size: 12px;
    }
    .foot-main-item h4{
      margin-bottom: 14px;
      font-size: 15px;
    }
}
@media screen and (max-width:1366px) and (max-height:768px) {
  .foot-main-item img {
        width: 101px;
        height: 20px;
    }
    .foot-main-item span {
        font-size: 12px;
    }
    .gonganContent span {
        font-size: 12px;
    }
    .foot-main-item h4{
      margin-bottom: 14px;
      font-size: 15px;
    }
}
@media screen and (max-width:1360px) and (max-height:768px) {
  .foot-main-item img {
        width: 101px;
        height: 20px;
    }
    .foot-main-item span {
        font-size: 12px;
    }
    .gonganContent span {
        font-size: 12px;
    }
    .foot-main-item h4{
      margin-bottom: 14px;
      font-size: 15px;
    }
}

/*修改有关间距的兼容问题*/
/*修改footer间距问题*/
@media screen and (max-width:1680px){
  .foot {
        padding: 25px 208px 25px 208px;
    }
}
@media screen and (max-width:1600px){
  .foot {
        padding: 25px 200px 25px 200px;
    }
}
@media screen and (max-width:1440px){
  .foot {
        padding: 25px 180px 25px 180px;
    }
}
@media screen and (max-width:1366px){
  .foot {
        padding: 25px 176px 25px 176px;
    }
}
@media screen and (max-width:1280px){
  .foot {
        padding: 25px 146px 25px 146px;
    }
}
</style>
