import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Advertisement from "@/components/Advertisement.vue";
import {
    apiGetFyMenu,
    apiGetArticleList,
} from "../../../../request/api.js";
export default {
    components: {
        Header,
        Footer,
        Advertisement
    }, //添加注册组件事件
    data() {
        return {
            list: [],
            plateId: '',
            pageSize: 20,
            page: 1,
            buttonShow: 1,
            navInd: 0,
            navListTitle: [],
            navChildren: 0, //关于公司领导点击默认

            //走进枫叶的标题以及图片区域
            bannerImg: "",
            bannerTitle: "",
            bannerSubTit: "",


            /*新闻列表*/
            fixedindex: "plate_intro",
            id: 0, //默认为枫叶咨询 

            /*公司简介*/
            textword: "",

            /*大事件*/
            BigMsgList: [],
            /*公司高管*/
            leaderold: [],
            leader: [],
            nolistdata: false, //新闻列表的没有数据情况
            nogaoguan: false
        };
    },
    created() {
        let plateId = unescape(this.$route.query.plateId);
        this.plateId = plateId;
        this.getFyMenu()
    },
    mounted() {},
    methods: {
        // 获取导航列表
        getFyMenu() {
            apiGetFyMenu({}).then(res => {
                this.navListTitle = res.data.data
                this.navInd = res.data.data[0].Id
                this.id = res.data.data[0].Id
                this.getjianjie(res.data.data[0].Id)
            });
        },
        // 点击导航
        navList(id, fixedindex) {
            this.navInd = id //将点击的id赋值到navInd中（用于点击active）
            this.fixedindex = fixedindex
            this.navChildren = 0
                //点击其他进行请求
            if (this.fixedindex == "plate_info") { //列表
                this.getfengyemsg(id, fixedindex)
            } else if (this.fixedindex == "plate_intro") { //简介
                this.getfengyemsg(id, fixedindex)
            } else if (this.fixedindex == "plate_notes") { //大事件
                this.getfengyemsg(id, fixedindex)
            } else if (this.fixedindex == "plate_executives") { //高管
                this.getfengyemsg(id, fixedindex)
            }
        },
        //默认展示公司简介
        getjianjie(id) {
            let jianjie = {
                fixed_index: "plate_intro",
                id: id,
            }
            apiGetArticleList(jianjie).then(res => {
                this.handCompanyprofile(res)
            });
        },
        // 获取走进枫叶接口数据（封装）
        getfengyemsg(type, fixedindex) {
            let parameter = {
                fixed_index: fixedindex,
                id: type,
            }
            if (fixedindex == "plate_info") {
                parameter.pageSize = this.pageSize;
                parameter.page = this.page;
            }
            apiGetArticleList(parameter).then(res => {
                this.handCompanyprofile(res) //处理公司简介
                if (res.data.code == 200) {
                    if (fixedindex == "plate_info") {
                        this.handleList(res) //处理枫叶咨询
                    } else if (fixedindex == "plate_intro") {
                        this.handCompanyprofile(res) //处理公司简介
                    } else if (fixedindex == "plate_notes") { //处理大事件
                        this.timeaxis(res)
                    } else if (fixedindex == "plate_executives") { //公司领导介绍
                        this.companyleader(res)
                    }
                }
            });
        },
        //根据type进行分别处理 start
        //type = 1处理有关枫叶咨询
        // type = 2处理公司简介
        // type = 3 处理大事件
        // type =4 处理公司高管
        handleList(res) { //type = 1
            this.list = [];
            let arr = res.data.data.list.data
            this.list = this.list.concat(arr)
            if (this.list.length >= res.data.data.list.total) {
                this.buttonShow = 2
            }
            if (res.data.data.list.data.length == 0) {
                this.nolistdata = true
            }
            /*以下是赋值到走进枫叶的图片以及标题区域*/
            this.bannerSubTit = res.data.data.plateSubTit
            this.bannerTitle = res.data.data.plateTit
            this.bannerImg = res.data.data.plateImage
        },
        handCompanyprofile(res) { //type = 2
            /*以下是赋值到走进枫叶的图片以及标题区域*/
            this.bannerSubTit = res.data.data.plateSubTit
            this.bannerTitle = res.data.data.plateTit
            this.bannerImg = res.data.data.plateImage
                //处理数据
            this.textword = res.data.data.list.text
        },
        timeaxis(res) { //type = 3
            /*以下是赋值到走进枫叶的图片以及标题区域*/
            this.bannerSubTit = res.data.data.plateSubTit
            this.bannerTitle = res.data.data.plateTit
            this.bannerImg = res.data.data.plateImage
                //处理数据
            this.BigMsgList = res.data.data.list
        },
        companyleader(res) { //type = 4
            /*以下是赋值到走进枫叶的图片以及标题区域*/
            this.bannerSubTit = res.data.data.plateSubTit
            this.bannerTitle = res.data.data.plateTit
            this.bannerImg = res.data.data.plateImage
                //处理数据
            this.leaderold = res.data.data.list
            this.leader = res.data.data.list[this.navChildren]
            if (res.data.data.list.length == 0) {
                this.nogaoguan = true
            }
        },
        //根据type进行分别处理 end
        loadFun() {
            this.page++
                this.newListChildren()
        },

        LinkDetails(id, title) {
            window.location.href = this.FunPublic.url + "newDetails.html?" + "id=" + escape(id) + "&title=" + escape(title) + "&typemodel=1"
        },
        //点击高管
        navChildrenList(index) {
            this.navChildren = index
            this.leader = this.leaderold[this.navChildren]
        }
    },
};